import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const ProfileSettings = ({ user, userAnswers, onSave }) => {
  const [name, setName] = useState(user.name);
  const [profileType, setProfileType] = useState(user.profileType);
  const [rookConnected, setRookConnected] = useState(false); 
  const [connectedDevices, setConnectedDevices] = useState([]);
  const [firebaseUserId, setFirebaseUserId] = useState(null);
  const [availableDataSources, setAvailableDataSources] = useState([]); // Store data sources
  const [showMessage, setShowMessage] = useState(false);
  const [showConnectionsPage, setShowConnectionsPage] = useState(false);
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchFirebaseUser = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        setFirebaseUserId(currentUser.uid);
      } else {
        console.error('No user is logged in!');
      }
    };

    fetchFirebaseUser();
  }, []);

  // Fetch connected devices from Firestore
  useEffect(() => {
    const fetchConnectedDevicesFromFirestore = async () => {
      if (!firebaseUserId) return;

      try {
        const docRef = doc(db, `users/${firebaseUserId}/rook_data/data_sources`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const dataSources = data.data_sources || []; // Ensure data_sources is an array

          // Filter data sources that are connected
          const activeSources = dataSources.filter(source => source.connected === true);
          setConnectedDevices(activeSources); // Set the active connected devices
          setAvailableDataSources(dataSources); // Set all available data sources
        } else {
          console.log('No data sources found for this user.');
        }
      } catch (error) {
        console.error('Error fetching connected devices from Firestore:', error);
      }
    };

    fetchConnectedDevicesFromFirestore();
  }, [firebaseUserId, db]);

  // Fetch rookConnected value from Firestore for initial state of the toggle
  useEffect(() => {
    const fetchRookConnected = async () => {
      if (!firebaseUserId) return;

      try {
        const docRef = doc(db, `users/${firebaseUserId}`);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setRookConnected(userData.rookConnected || false); // Default to false if field doesn't exist
        } else {
          console.log('No user data found!');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchRookConnected();
  }, [firebaseUserId, db]);

  // Handle toggle change to update rookConnected in Firestore
  const handleToggleChange = async () => {
    if (!firebaseUserId) return;

    const newRookConnectedState = !rookConnected; // Toggle the state
    setRookConnected(newRookConnectedState);

    try {
      const userDocRef = doc(db, `users/${firebaseUserId}`);
      await updateDoc(userDocRef, {
        rookConnected: newRookConnectedState,
      });
      console.log('rookConnected updated successfully!');
    } catch (error) {
      console.error('Error updating rookConnected:', error);
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (name.trim() === '' || profileType.trim() === '') {
      alert("Fields can't be empty.");
      return;
    }
    onSave({ name, profileType });
    setShowMessage(true);
    setTimeout(() => navigate('/dashboard'), 1500);
  };

  const renderConnectButton = (dataSource) => {
    // Check if the data source is already connected
    if (dataSource.connected) return null; // If connected, don't render the button

    const handleConnect = () => {
      const popup = window.open(dataSource.authorization_url, '_blank', 'width=600,height=600,scrollbars=yes,resizable=yes');
      // Optionally check for when the popup closes and redirect user back to settings
      const pollPopup = setInterval(() => {
        if (popup.closed) {
          clearInterval(pollPopup);
          // Reload the settings page after connection
          navigate('/settings');
        }
      }, 500); // Check every 500ms if the popup is closed
    };

    return (
      <button
        key={dataSource.name}
        onClick={handleConnect} // Handle the connection process
        style={styles.connectButton}
        disabled={!dataSource.authorization_url} // Disable button if no authorization URL is present
      >
        <img
          src={dataSource.image} // Use the image URL from the data source
          alt={dataSource.name}
          style={{ width: '24px', height: '24px', marginRight: '8px' }}
        />
        Connect {dataSource.name}
      </button>
    );
  };

  return (
    <div style={styles.container}>
      <h2>Profile Settings</h2>
      <form onSubmit={handleSave} style={styles.form}>
        <div style={styles.inputContainer}>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputContainer}>
          <label>Profile Type</label>
          <input
            type="text"
            value={profileType}
            onChange={(e) => setProfileType(e.target.value)}
            style={styles.input}
          />
        </div>
        {/* Toggle for Data Collection Opt-In */}
        <div style={styles.toggleContainer}>
          <label htmlFor="rookConnectedToggle" style={styles.toggleLabel}>
            Data Collection Opt-In
          </label>
          <input
            type="checkbox"
            id="rookConnectedToggle"
            checked={rookConnected}
            onChange={handleToggleChange}
            style={styles.toggleInput}
          />
        </div>
        <button type="submit" style={styles.saveButton}>Save</button>
        <button type="button" onClick={() => navigate('/dashboard')} style={styles.backButton}>Back</button>
      </form>

      {showMessage && <p style={styles.confirmationMessage}>Profile updated successfully!</p>}

      {userAnswers && (
        <div style={styles.answersContainer}>
          <h3>Your Initial Questions and Answers</h3>
          <ul>
            {userAnswers.map((qa, index) => (
              <li key={index}>
                <strong>{qa.questionTitle}:</strong> {qa.answer || 'Not answered'}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Connected Wearables */}
      <div style={styles.wearablesSection}>
        <h3>Connected Wearable Devices</h3>
        {connectedDevices.length > 0 ? (
          <ul style={styles.deviceList}>
            {connectedDevices.map((device) => (
              <li key={device.name} style={styles.deviceItem}>
                <img
                  src={device.image} // Use the image URL from the device object
                  alt={device.name}
                  style={{ width: '24px', height: '24px', marginRight: '8px' }}
                />
                <span>{device.name}</span> {/* Display the device name */}
              </li>
            ))}
          </ul>
        ) : (
          <div>
            <p>No devices connected yet.</p>
          </div>
        )}
      </div>

      {/* Connect Unconnected Data Sources */}
      <div style={styles.connectDevicesSection}>
        <h3>Connect a Wearable Device</h3>
        {availableDataSources.map(renderConnectButton)} {/* Render connect buttons */}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    textAlign: 'center',
  },
  form: {
    marginTop: '20px',
  },
  inputContainer: {
    marginBottom: '15px',
    textAlign: 'left',
  },
  input: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  saveButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  backButton: {
    padding: '10px 20px',
    backgroundColor: '#6c757d',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  confirmationMessage: {
    marginTop: '15px',
    color: '#28a745',
  },
  answersContainer: {
    marginTop: '30px',
    textAlign: 'left',
  },
  wearablesSection: {
    marginTop: '30px',
    textAlign: 'left',
  },
  connectDevicesSection: {
    marginTop: '30px',
    textAlign: 'left',
  },
  connectButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  deviceList: {
    listStyle: 'none',
    padding: 0,
  },
  deviceItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
};

export default ProfileSettings;
