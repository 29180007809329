import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Box, Text, Grid, Flex, Spinner } from '@chakra-ui/react';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import './Dashboard.css';

const Dashboard = ({ userData }) => {
  const [fitbitData, setFitbitData] = useState(null);
  const [garminData, setGarminData] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getFirestore();

  // Fetch Fitbit and Garmin data from Firestore
  useEffect(() => {
    if (!userData || !userData.uid) {
      console.error('userData or userData.uid is undefined');
      return; // Exit early if userData is not available
    }

    const fetchData = async () => {
      try {
        const fitbitData = await getSubcollectionData('fitbit_data');
        const garminData = await getSubcollectionData('garmin_data');

        setFitbitData(fitbitData || null);
        setGarminData(garminData || null);
      } catch (error) {
        console.error('Error fetching subcollection data:', error);
      } finally {
        setLoading(false);
      }
    };

    const getSubcollectionData = async (subcollectionName) => {
      const docRef = collection(db, `users/${userData.uid}/${subcollectionName}`);
      const snapshot = await getDocs(docRef);
      const data = snapshot.docs.map((doc) => doc.data());
      return data.length > 0 ? data[0] : null;
    };

    fetchData();
  }, [db, userData]);

  // Check if both Fitbit and Garmin data are missing
  const noData = !fitbitData && !garminData;

  // Aggregating data from Fitbit and Garmin
  const aggregatedData = aggregateEventData(fitbitData, garminData);

  // if (loading) {
  //   return <Spinner size="xl" />;
  // }

  // if (!aggregatedData) {
  //   return <Text>No data available for dashboard</Text>;
  // }

  return (
    <Box padding="20px">
      {/* Total Stats */}
      <Grid templateColumns="repeat(3, 1fr)" gap={6} marginTop="30px">
        {/* Check if Garmin data exists */}
        {garminData && (
          <>
            <Box textAlign="center" border="1px solid lightgray" padding="20px">
              <Text fontSize="2xl">Garmin Total Distance</Text>
              <Text fontSize="4xl">{garminData?.physical_summary?.distance?.traveled_distance_meters_float ?? 0} m</Text>
            </Box>
            <Box textAlign="center" border="1px solid lightgray" padding="20px">
              <Text fontSize="2xl">Garmin Total Time</Text>
              <Text fontSize="4xl">{garminData?.physical_summary?.activity?.active_seconds_int ?? 0} s</Text>
            </Box>
            <Box textAlign="center" border="1px solid lightgray" padding="20px">
              <Text fontSize="2xl">Garmin Total Calories</Text>
              <Text fontSize="4xl">{garminData?.physical_summary?.calories?.calories_expenditure_kcal_float ?? 0} kcal</Text>
            </Box>
          </>
        )}
        
        {/* Check if Fitbit data exists */}
        {fitbitData && (
          <>
            <Box textAlign="center" border="1px solid lightgray" padding="20px">
              <Text fontSize="2xl">Fitbit Total Steps</Text>
              <Text fontSize="4xl">{fitbitData?.summary?.steps ?? 0} steps</Text>
            </Box>
            <Box textAlign="center" border="1px solid lightgray" padding="20px">
              <Text fontSize="2xl">Fitbit Total Active Minutes</Text>
              <Text fontSize="4xl">{fitbitData?.summary?.activeMinutes ?? 0} mins</Text>
            </Box>
            <Box textAlign="center" border="1px solid lightgray" padding="20px">
              <Text fontSize="2xl">Fitbit Calories Burned</Text>
              <Text fontSize="4xl">{fitbitData?.summary?.caloriesOut ?? 0} kcal</Text>
            </Box>
          </>
        )}
      </Grid>

      {/* Aggregated data visualizations */}
      <Box height="300px" marginTop="40px">
        {aggregatedData.pieChartData?.length > 0 ? (
          <ResponsivePie
            data={aggregatedData.pieChartData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'nivo' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          />
        ) : (
          <Text>No data available for pie chart</Text>
        )}
      </Box>

      <Box height="400px" marginTop="40px">
        {aggregatedData.lineChartData?.length > 0 ? (
          <ResponsiveLine
            data={aggregatedData.lineChartData}
            margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 'auto',
              max: 'auto',
              stacked: true,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: 'bottom',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'event date',
              legendOffset: 36,
              legendPosition: 'middle',
            }}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'values',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
          />
        ) : (
          <Text>No data available for line chart</Text>
        )}
      </Box>

      {/* JSON Display Section */}
      <Box marginTop="40px" textAlign="left">
        <Text fontSize="xl" marginBottom="10px">Raw JSON Data</Text>
        <pre>{JSON.stringify({ fitbitData, garminData }, null, 2)}</pre>
      </Box>
    </Box>
  );
};

// Function to aggregate data from Fitbit and Garmin summaries
const aggregateEventData = (fitbitData, garminData) => {
  const lineChartData = [];

  // Fitbit steps data
  if (fitbitData?.summary?.steps) {
    lineChartData.push({
      id: 'fitbit_steps',
      data: [
        { x: 'Steps', y: fitbitData.summary.steps }
      ],
    });
  }

  // Garmin activity data
  if (garminData?.physical_summary?.activity?.active_seconds_int) {
    lineChartData.push({
      id: 'garmin_activity',
      data: [
        { x: 'Active Seconds', y: garminData.physical_summary.activity.active_seconds_int }
      ],
    });
  }

  const pieChartData = [
    { id: 'Fitbit Steps', label: 'Fitbit Steps', value: fitbitData?.summary?.steps || 0 },
    { id: 'Garmin Activity', label: 'Garmin Activity', value: garminData?.physical_summary?.activity?.active_seconds_int || 0 },
  ];

  return {
    lineChartData,
    pieChartData,
  };
};

export default Dashboard;