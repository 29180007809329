import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth, onAuthStateChanged } from './firebase';
import { getFirestore, doc, getDoc } from 'firebase/firestore'; // Import Firestore
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import './App.css';
import NavBar from './NavBar';
import Header from './Header';
import RecentActivities from './RecentActivities';
import WearablesSummary from './WearablesSummary';
import GoalTracking from './GoalTracking';
import Dashboard from './Dashboard';
import Login from './Login';
import ProfileSettings from './ProfileSettings';
import PrivateRoute from './PrivateRoute';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userAnswers, setUserAnswers] = useState(() => {
    return JSON.parse(localStorage.getItem('userAnswers')) || null;
  });

  const [userData, setUserData] = useState({
    name: '',
    profilePicture: '',
    profileType: '',
    totalDistance: 0,
    totalTime: 0,
    totalCalories: 0,
    uid: '',
  });

  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState(null);

  const db = getFirestore();

  // Check authentication state and fetch user data
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('User logged in:', user); // Log user details
        setIsLoggedIn(true);
  
        // Fetch user data from Firestore based on user UID
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
          const userDataFromFirestore = userDocSnap.data();
          console.log('User data from Firestore:', userDataFromFirestore); // Debugging log
  
          setUserData({
            name: userDataFromFirestore.displayName || 'Agent 000',
            profilePicture: userDataFromFirestore.photoURL || '',
            profileType: userDataFromFirestore.profileType || 'Secret Squirrel',
            totalDistance: userDataFromFirestore.totalDistance || 0,
            totalTime: userDataFromFirestore.totalTime || 0,
            totalCalories: userDataFromFirestore.totalCalories || 0,
            uid: user.uid || '',
          });
        } else {
          console.log('No user data found in Firestore!');
        }
      } else {
        setIsLoggedIn(false);
      }
      setLoading(false);
    });
  
    return () => unsubscribe();
  }, [db]);  

  // Fetch activity data if logged in
  useEffect(() => {
    if (isLoggedIn) {
      fetch('/data/garmin/af_celsius_2_28_2024.json')
        .then(response => response.json())
        .then(data => setActivityData(data))
        .catch(error => console.error('Error loading JSON:', error));
    }
  }, [isLoggedIn]);

  // Show loading state while checking auth
  if (loading) {
    return <div>Loading...</div>;
  }

  const handleLogin = (user) => {
    setUserData((prevData) => ({ ...prevData, email: user.email }));
    setTimeout(() => {
      setIsLoggedIn(true);
    }, 100);
  };

  const handleSubmitTypeform = (userAnswersFromModal, questions) => {
    const formattedAnswers = questions.map((question) => ({
      questionTitle: question.title,
      answer: userAnswersFromModal[question.id] || '',
    }));

    setUserAnswers(formattedAnswers);
    localStorage.setItem('userAnswers', JSON.stringify(formattedAnswers));
    setShowModal(false);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('userAnswers');
  };

  const handleSaveProfile = (updatedUser) => {
    setUserData((prevData) => ({
      ...prevData,
      name: updatedUser.name,
      profileType: updatedUser.profileType,
      profilePicture: updatedUser.profilePicture || prevData.profilePicture,
    }));
  };

  // const handleSaveGoals = (updatedGoals) => {
  //   setGoals(updatedGoals);
  // };

  return (
    <Router>
      <div className="App">
        {isLoggedIn && <NavBar user={userData} onLogout={handleLogout} />}
        <Routes>
          {/* Redirect to /dashboard if already logged in */}
          <Route path="/" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login onLogin={handleLogin} />} />
          {/* Protected routes that require login */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <Header user={userData} totalActivities={5} activeDays={[30, 1, 3, 7, 15]} />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <RecentActivities />
                  {/* <GoalTracking goals={goals} /> */}
                  <WearablesSummary activityData={activityData} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {/* <Dashboard userData={userData} /> */}
                </div>
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute isLoggedIn={isLoggedIn}>
                <ProfileSettings
                  user={userData}
                  userAnswers={userAnswers}
                  onSave={handleSaveProfile}
                />
              </PrivateRoute>
            }
          />
          {/* <Route path="/upload/*" element={<PrivateRoute isLoggedIn={isLoggedIn}><Upload /></PrivateRoute>} /> */}
        </Routes>
        {/* {showModal && (
          <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
            <TypeformQuestions onSubmit={handleSubmitTypeform} />
          </Modal>
        )} */}
      </div>
    </Router>
  );
}

export default App;
